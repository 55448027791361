<template>
  <div class="mb-4">
    <template v-for="(obj, iQues) in dataMockConversation">
      <div :key="iQues" class="mb-1">
        <div style="display: flex" class="ml-12">
          <h4
              :id="'question' + iSkill + iSection + iExercise + iQues"
              class="mr-4 mt-2">
            <template v-if="type_expand == 0">
              {{
                iQues + section.offset_question + exercise.offset_question + 1
              }}.
            </template>
            <template v-if="type_expand == 1"> {{ iQues + 1 }}. </template>
          </h4>
          <v-list-item-avatar v-if="getObjCharacter(obj.character_id)" class="m-0">
            <img
                :src="getObjCharacter(obj.character_id).avatar"
                :alt="getObjCharacter(obj.character_id).name"
            >
          </v-list-item-avatar>
          <span
              class="text-subtitle-1 ml-2 mt-2"
              v-if="getObjCharacter(obj.character_id)">
            {{ getObjCharacter(obj.character_id).name.trim() }} -
            {{ getObjCharacter(obj.character_id).gender === 'male' ? "Nam" : "Nữ" }} -
            {{ getObjCharacter(obj.character_id).job }} :
          </span>
          <pre class="text-body-1 ml-2 mt-2">{{obj.script.trim() }}</pre>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MockConversationType",
  props: {
    section: {
      type: Object,
      default() {
        return {};
      },
    },
    exercise: {
      type: Object,
      default() {
        return {};
      },
    },
    iSkill: {
      type: Number,
      default: 0,
    },
    iSection: {
      type: Number,
      default: 0,
    },
    iExercise: {
      type: Number,
      default: 0,
    },
    type_expand: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataMockConversation: [],
    }
  },
  computed: {
    ...mapState({
      dataCharacterConfig: (state) => state.exerciseConfigStore.characters,
    }),
  },
  created() {
    if (this.exercise?.body?.dataMockConversationQuestion) {
      this.dataMockConversation = this.exercise.body.dataMockConversationQuestion;
    }
  },
  methods: {
    getObjCharacter(character_id) {
      return this.dataCharacterConfig.find((item) => item.id === character_id);
    },
  },
}
</script>

<style scoped>

</style>